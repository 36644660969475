import React, { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import Fieldset from "theme/components/atoms/Form/Fieldset";
import FormActions from "theme/components/molecules/Form/FormActions";
import { Hidden } from "theme/components/atoms/Form/Input";
import { useEmailServerValidation } from "theme/modules/SmartForms/Field/Email";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import Stack from "theme/components/atoms/Layout/Stack";
import FormItem from "theme/components/molecules/Form/Item";
import CustomerExistsQuery from "./CustomerExistsQuery.gql";
import { useHistory } from "react-router";
import Spinner from "front-commerce/src/web/theme/components/atoms/Spinner";
import { AlertError } from "theme/components/molecules/Alert";
import LoginWithFacebook from "theme/modules/User/LoginWithFacebook";
import EnhanceCapencyModal from "./EnhanceCapencyModal";
import ModalCapency from "theme/components/organisms/Modal/ModalCapency";
import RequiredFields from "theme/components/atoms/Form/RequiredFields";
import { Email } from "theme/components/atoms/Form/Input";

const messages = defineMessages({
  emailAddress: {
    id: "modules.User.PasswordManagement.PasswordRequestForm.label",
    defaultMessage: "Email address",
  },
  emailLabel: {
    id: "modules.User.RegisterForm.email",
    defaultMessage: "Email address",
  },
  emailPlaceholder: {
    id: "modules.User.RegisterForm.emailPlaceholder",
    defaultMessage: "ex: name.firstname@mail.com",
  },
  emailErrorRequired: {
    id: "modules.User.LoginForm.email.errorRequired",
    defaultMessage: "Please enter an email",
  },
  emailErrorMalformed: {
    id: "modules.User.LoginForm.email.errorMalformed",
    defaultMessage: "Please enter a valid email address. For example pierremaistre@domaine.com.",
  },
  useExistError: {
    id: "modules.User.RegisterForm.userExistError",
    defaultMessage: "This customer email already exists",
  },
});

const CheckExistingUserForm = ({
  initialValues,
  showPlaceHolder,
  redirect,
  onIsNewUser,
  actionOnRegister,
  onLoginSuccess,
  capencyModalState,
  closeCapencyModal,
  showCapencyModalError,
  showCapencyModalWarn,
  setCapencyLoading,
  setCapencyModalIsOpen,
  fbButtonAppearrance,
  ...props
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [hasError, setHasError] = useState(false);

  // Check if email is not already registered to Kaporal.
  const handleFormSubmit = (formData) => {
    checkEmailExists({
      variables: { email: formData.userEmail },
    });
  }

  // Email validation by Capency.
  const onSubmit = useEmailServerValidation(
    "userEmail",
    setCapencyLoading,
    handleFormSubmit,
    {
      valid: (context) => {
        closeCapencyModal();
        context.submit(context.formModel);
      },
      disabled: () => {
        closeCapencyModal();
      },
      warn: (context) => {
        const {
          formModel: { userEmail },
          validationResult: { message}
        } = context;
        showCapencyModalWarn({
          message,
          value: userEmail,
          handleSubmitForm: () => {
            closeCapencyModal();
            context.submit(context.formModel);
          },
          elementId: 'user-email',
        });
      },
      error: (context) => {
        const {
          formModel: { userEmail },
          validationResult: { message}
        } = context;
        showCapencyModalError({
          message,
          value: userEmail,
          elementId: 'user-email',
        });
      }
    });

  // Query to check if email is already used by an account.
  const [checkEmailExists, { loading, data }] = useLazyQuery(
    CustomerExistsQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (!result.customerExists.success) {
          history.push("/register", {
            registerFormData: { email: result.customerExists.email },
            ...(actionOnRegister && { actionOnRegister }),
          });
        } else {
          setHasError(true);
        }
      },
    }
  );

  if (loading) {
    return <Spinner />;
  }

  const handleFormChange = (currentValues, isChanged) => {
    if (isChanged) {
      setHasError(false);
    }
    if (typeof props.onChange === "function") {
      props.onChange(currentValues, isChanged);
    }
  };

  return (
    <>
      {
        capencyModalState.isOpen &&
        <ModalCapency
          {...capencyModalState}
          setIsOpen={setCapencyModalIsOpen}
        />
      }
      <Form
        onChange={handleFormChange}
        onValidSubmit={onSubmit}
      >
        {hasError && data?.customerExists?.success && (
          <AlertError key="error">
            <FormattedMessage {...messages.useExistError} />
          </AlertError>
        )}
        <Fieldset>
          <Stack desktopSize="2" mobileSize="4">
            {props.errorMessage && (
              <AlertError key="error">{props.errorMessage}</AlertError>
            )}
            {initialValues?.orderId && (
              <Hidden
                name="orderId"
                id="orderId"
                value={initialValues.orderId}
              />
            )}

            <Stack key="form" desktopSize="1" mobileSize="2">
              <div className="form-item__autocomplete">
                <FormItem label={intl.formatMessage(messages.emailAddress)}>
                  <Email
                    name="userEmail"
                    id="user-email"
                    required
                    aria-label={intl.formatMessage(messages.emailAddress)}
                    placeholder={intl.formatMessage(messages.emailPlaceholder)}
                    autoComplete="email"
                    value={data?.customerExists?.email}
                    validationErrors={{
                      // If form validated but no email
                      required: intl.formatMessage(messages.emailErrorRequired),
                      // If error email 
                      isEmail: intl.formatMessage(messages.emailErrorMalformed),
                    }}
                  />
                </FormItem>
              </div>
            </Stack>
            <FormActions key="actions" appearance="full">
              <SubmitButton
                size="big"
                type="submit"
                appearance="primary"
                state={capencyModalState.isLoading ? "pending" : undefined}
              >
                <FormattedMessage
                  id="modules.RegisterForm.create"
                  defaultMessage="Create my account"
                />
              </SubmitButton>
            </FormActions>
            {props.additionalActions}
          </Stack>
        </Fieldset>
      </Form>
      {props.enableFacebook && (
        <div className="form-actions__element">
          <div className="bordered">
            <span className="login-alt">
              <FormattedMessage
                id="components.organisms.Login.LoginForm.alt"
                defaultMessage="Or"
              />
            </span>
          </div>
          <LoginWithFacebook
          fbButtonAppearrance={fbButtonAppearrance}       
          onLoginSuccess={onLoginSuccess} />
        </div>
      )}
       <RequiredFields/>
    </>
  );
};

export default EnhanceCapencyModal()(CheckExistingUserForm);
